.grey-background {
    background-color: rgb(209, 207, 207);
}

.white-background {
    background-color: white;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.1); /* Change border color to white */
    border-left-color: #fff; /* Change left border color to white */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
